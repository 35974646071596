import dayjs from "dayjs";
import { log, error, parseBinary, updateBarSubscribers } from "./helpers";
import store from "./store";
import { SocketMessage } from "../types";
import { CODE, MODE } from "../constants";

// -------------------------------------------------
// socket endpoint
const SOCKET_URL = process.env.SOCKET_URL;
if (!SOCKET_URL) {
  throw new Error("SOCKET_URL is not defined");
}
// formatted socket url
const url = `${SOCKET_URL}?session=${store.getAuthSession()}&token=${store.getAuthToken()}`;
// socket instance
let socket: WebSocket;
// respresents current ping checker id
let pingCheckerID: NodeJS.Timeout;
// most recent ping received from socket server
let lastPingTS: dayjs.Dayjs;
// list of pending requests waiting to be processed
let pendingQueue: (string | SocketMessage)[] = [];
// number of retry connections attempts made
let retryCount = 0;
// max retry connection attempts allowed
const maxRetries = 20;
// last reconnect request id.
let reconnectRequestID: NodeJS.Timeout | undefined;

// ConnectSocket -------------------------------
export const connectSocket = () => {
  socket = new WebSocket(url);
  socket.onopen = onOpen;
  socket.onerror = onError;
  socket.onclose = onClose;
  socket.onmessage = onMessage;
};

// onOpen ---------------------------------------
const onOpen = (event: Event) => {
  log("🟢 Connected to socket ");
  retryCount = 0;
  startPingChecker();
  subscribePreviousSubscriptions();
  processPendingRequests();
};

// onError -------------------------------------------
const onError = (event: Event) => {
  error("⛔ Error while connection to socket", event);
};

// onClose -------------------------------------------
const onClose = (event: CloseEvent) => {
  log("🔴 Socket connection closed.", event);
  reconnect();
};

// onMessage -----------------------------------------
const onMessage = (event: MessageEvent) => {
  // Handle incoming messages from the server

  if (event.data === "PING") {
    lastPingTS = dayjs();
    log("⬇ Received message:", event.data);
    emit("PONG");
  } else if (event.data instanceof Blob) {
    const reader = new FileReader();
    reader.onload = () => {
      // Parse the binary data
      const tick = parseBinary(reader.result as ArrayBuffer);
      log("⬇ Received message:", tick);
      // update all listeners
      updateBarSubscribers(tick);
    };
    reader.onerror = () => {
      console.error("Error reading binary data.");
    };
    reader.readAsArrayBuffer(event.data);
  } else {
    log("⬇🫤 Unknown message received:", event.data);
  }
};

/* Emit ------------------------------------
 Emits messages to socket server. If the connection is Open, will be sent immediately,
 else will be queued and sent once connected.
 Incase do want want to queue a request. set volatile to true.
*/
export const emit = (
  message: string | SocketMessage,
  volatile: boolean = false
) => {
  message = typeof message === "string" ? message : JSON.stringify(message);

  if (socket.readyState === WebSocket.OPEN) {
    // emit only if connection open
    log("⬆ Emitted to socket: ", message);
    socket.send(message);
  } else {
    // put request to queue
    error(
      `❗ Error emitting to socket because socket state is: ${
        socket.readyState
      }. ${!volatile ? "Request queued until reconnection" : ""}`
    );
    if (!volatile) {
      pendingQueue.push(message);
    }
  }
};

/* Reconnect ----------------------------------------------
  Attempts to reconnect to the websocket server until number
  of reconnect attempts exceeds maxRetries limit.
*/
const reconnect = () => {
  if (reconnectRequestID) {
    // already reconnect requested.
    log(
      `🙈 Reconnect request ignored. Already requested by ${reconnectRequestID}`
    );
    return;
  }

  if (retryCount >= maxRetries) {
    // reconnect limit is reached
    log("✋ Socket reconnection limit reached.");
    return;
  }

  ++retryCount;

  // Attempt to reconnect after a delay
  reconnectRequestID = setTimeout(() => {
    // clear reconnect request
    reconnectRequestID = undefined;
    connectSocket();
  }, 3000); // Reconnect after 3 seconds
  log(
    `🔄 Attempting socket reconnect in 3 Sec. Request id: ${reconnectRequestID} `
  );
};

/* StartPingChecker -----------------------------------------
 !TO BE ONLY CALLED IN "onOpen" METHOD OF WEBSOCKET
  Will call pingchecker every 35 seconds to check if difference 
  between current time and last ping is atmost 35 seconds.
  !Backend pings every 30 seconds. Hence difference must be less than that.
  If not will try to reconnect.
*/
const startPingChecker = () => {
  // clearing any previous ping checkers
  clearInterval(pingCheckerID);

  // recording current time as recent ping
  lastPingTS = dayjs();

  pingCheckerID = setInterval(() => {
    const diff = dayjs().diff(lastPingTS, "seconds");
    if (diff > 35) {
      log(`🆚 Socket ping difference exceeded: ${diff}. Reconnecting... `);
      reconnect();
    }
  }, 35000);
};

/*
  ProcessPendingRequests --------------------------------------
  !TO BE ONLY CALLED IN "onOpen" METHOD OF WEBSOCKET
  Processes requests which were not processed due to socket state other than OPEN.
*/
const processPendingRequests = () => {
  // Process pending requests.
  if (pendingQueue.length > 0) {
    log("⏳ Processing pending requests...");

    const pendingQueueCopy = pendingQueue;
    pendingQueue = []; // init orignal with fresh

    pendingQueueCopy.forEach((request) => {
      emit(request);
    });
  }
};

/* subscribePreviousSubscriptions --------------------------------
  !TO BE ONLY CALLED IN "onOpen" METHOD OF WEBSOCKET
  Emits subscription requests in store to socket.
  Helpful if socket abruptly disconnects.
*/
const subscribePreviousSubscriptions = () => {
  log("⏳ Processing previous subscriptions if any...");
  for (const token of store.getAllSocketSubscriptions().keys()) {
    emit({
      code: CODE.SUB,
      mode: MODE.FULL,
      full: [token],
    });
  }
};
