// disable eslint for file
/* eslint-disable */
class Search {
    constructor() {
      r(
        this,
        'regSymbol',
        RegExp(
          /(.+?)((-EQ)|([0-9]{2})(([A-Z]{3})|(([0-9OND])([0-9]{2})))(FUT|([0-9.]+)(CE|PE)(W.)?))/i
        )
      ),
        r(
          this,
          'regWeeklyExpiry',
          RegExp(/([0-9]{2})(([A-Z]{3})|(([0-9OND])([0-9]{2})))/i)
        ),
        (this.maxResults = 25),
        (this.lastResults = null),
        (this.currentYear = new Date().getFullYear()),
        (this.weeklyMonthsMap = {
          1: 'JAN',
          2: 'FEB',
          3: 'MAR',
          4: 'APR',
          5: 'MAY',
          6: 'JUN',
          7: 'JUL',
          8: 'AUG',
          9: 'SEP',
          O: 'OCT',
          N: 'NOV',
          D: 'DEC',
        }),
        (this.strikePrecision = {}),
        (this.eventsInstruments = {}),
        (this.defaultTickSize = {
          'MCX-OPT': 1,
          'MCX-FUT': 1,
          MCX: 1,
        }),
        (this.tokenMap = {});
    }
    init(e) {
      (this.months = e.months),
        (this.weeklyMonthsMap = e.weekly_months),
        (this.segments = this.arrayToSet(e.segments)),
        (this.segmentsList = e.segments),
        (this.equitySegments = this.arrayToSet(e.equity_segments)),
        (this.optionsSegments = this.arrayToSet(e.options_segments)),
        (this.futuresSegments = this.arrayToSet(e.futures_segments)),
        (this.exchangeSegments = this.arrayToSet(e.exchange_segments)),
        (this.tradeableSegments = this.arrayToSet(e.tradeable_segments)),
        (this.segmentsID = e.segments_id_map),
        (this.segmentsAliases = e.segments_aliases),
        (this.segmentsExchangeMap = e.segments_exchange_map),
        (this.eventsInstruments = Object.assign({}, this.arrayToMap(e.events))),
        (this.instrumentsMap = {}),
        (this.instrumentsArray = {}),
        (this.equitySymbolMap = []),
        (this.tokenMap = {});
      for (let t of e.equity_segments)
        this.buildEquitySymbolMap(t, e.instruments[t]);
      for (let t of e.segments)
        e.instruments[t] && this.feed(t, e.instruments[t]);
      const segments = Object.keys(this.instrumentsMap);
      for (let i = 0; i < segments.length; i += 1) {
        // loop through all segments
        const instruments = this.instrumentsMap[segments[i]];
  
        // assign all tokens to tokenMap
        const instrumentKeys = Object.keys(instruments);
        for (let j = 0; j < instrumentKeys.length; j += 1) {
          this.tokenMap[instruments[instrumentKeys[j]][0]] = instrumentKeys[j];
        }
      }
    }
    buildEquitySymbolMap(e, t) {
      for (let s of t) {
        let t = s[5] || s[1];
        this.equitySymbolMap[t] || (this.equitySymbolMap[t] = []),
          this.equitySymbolMap[t].push([e, s[1]]);
      }
    }
    feed(e, t) {
      (this.instrumentsMap[e] = {}),
        (this.instrumentsArray[e] = []),
        this.equitySegments.has(e) || 'INDICES' === e
          ? this.loadEquity(e, t)
          : this.optionsSegments.has(e)
          ? this.loadOptions(e, t)
          : this.futuresSegments.has(e)
          ? this.loadFutures(e, t)
          : console.log('skip loading segment: ', e);
    }
    search(e, t) {
      t || (t = this.maxResults);
      let s = this.tokenize(e),
        r = this.searchSegments(s),
        a = this.subtract(s, r.matchedTokens),
        n = this.rankSegments(r.results);
      0 === n.length && (n = this.allSegements());
      let i = this.searchSymbols(n, a, e, t);
      if (i.length > 0) this.lastResults = i;
      else if (this.lastResults) return this.lastResults;
      return i;
    }
    get(e, t, s) {
      if (!e) e = '';
      // TODO this is rerendering everytime
      // console.log(e, t,s)
      if (
        ((e = e.toUpperCase()),
        -1 !== e.indexOf('-EQ') && (e = e.split('-EQ')[0]),
        s && !t && (t = this.getSegment(e, s)),
        !s && t && (s = this.getExchange(t)),
        e && t && this.instrumentsMap[t])
      ) {
        let s = this.instrumentsMap[t][e];
        if (s) {
          let e = this.makeInstrument({
            exchangeToken: s[0],
            tradingSymbol: s[1],
            segment: t,
            exchange: this.segmentsExchangeMap[t],
            tickSize: s[2],
            lotSize: s[3],
            company: s[4] || '',
            isin: s[5] || '',
          });
          return (e.isFound = !0), e;
        }
      }
      let r = this.makeInstrument({
        exchangeToken: 0,
        tradingSymbol: e,
        segment: t || s,
        exchange: s || t,
        tickSize: this.defaultTickSize[s || t] || 0.05,
        lotSize: 1,
        company: '',
        isin: '',
      });
      return (r.isFound = !1), r;
    }
    getSegment(e, t) {
      if (this.exchangeSegments.has(t)) return t;
      let s = this.regSymbol.exec(e);
      return s
        ? 'FUT' === s[10]
          ? t + '-FUT'
          : s[11] && s[12]
          ? t + '-OPT'
          : null
        : null;
    }
    getExchange(e) {
      return e.split('-')[0];
    }
    loadEquity(e, t) {
      let s = [],
        r = {},
        a = -1,
        n = -1,
        i = -1;
      for (let o of t) {
        let e,
          t,
          u,
          l = o[1],
          c = o[2];
        (e = -1 === a ? o[0] : a + o[0]),
          (a = e),
          o[3] ? ((t = o[3]), (n = t)) : (t = n),
          o[4] ? ((u = o[4]), (i = u)) : (u = i);
        let h = [e, l, t, u, c, o[5]];
        s.push(h), (r[l] = h);
      }
      (this.instrumentsArray[e] = s), (this.instrumentsMap[e] = r);
    }
    loadOptions(e, t) {
      let s = [],
        r = {};
      for (let a = 0; a < t.length; a++) {
        let n = t[a],
          i = n[0],
          o = n[1],
          u = n[2];
        for (let t = 0; t < n[3].length; t++) {
          let a = n[3][t][0],
            l = n[3][t][1];
          for (let t in l)
            if (l.hasOwnProperty(t)) {
              let n = l[t],
                c = -1,
                h = -1;
              for (let l = 0; l < n.length; l++) {
                let d, p, m;
                -1 === c && -1 === h
                  ? ((p = n[l][0]), (d = n[l][1]))
                  : ((p = c + n[l][0]),
                    (d =
                      Math.round(
                        h * Math.pow(10, 9) + n[l][1] * Math.pow(10, 9)
                      ) / Math.pow(10, 9))),
                  (h = d),
                  (c = p),
                  (m = n[l][2] ? n[l][2] : u);
                let g = '';
                g = this.strikePrecision[e]
                  ? i + a + parseFloat(d).toFixed(this.strikePrecision[e]) + t
                  : i + a + d + t;
                let f = this.expandExpiryString(a),
                  S = f !== a ? f : null,
                  y = [p, g, o, m, S];
                s.push(y), (r[y[1]] = y);
              }
            }
        }
      }
      (this.instrumentsArray[e] = s), (this.instrumentsMap[e] = r);
    }
    loadFutures(e, t) {
      let s = [],
        r = {};
      for (let a = 0; a < t.length; a++) {
        let e = t[a][0],
          n = -1,
          i = t[a][1],
          o = t[a][2];
        for (let u = 0; u < t[a][3].length; u++) {
          let l, c, h;
          (h = -1 === n ? t[a][3][u][0] : n + t[a][3][u][0]),
            (n = h),
            (l = t[a][3][u][1]),
            (c = t[a][3][u][2] ? t[a][3][u][2] : o);
          let d = this.expandExpiryString(l),
            p = d !== l ? d : null,
            m = [h, e + l + 'FUT', i, c, p];
          s.push(m), (r[m[1]] = m);
        }
      }
      (this.instrumentsArray[e] = s), (this.instrumentsMap[e] = r);
    }
    searchSymbols(e, t, s, r) {
      let a = [];
      for (let n of this.segmentsList) {
        if (!this.instrumentsArray[n]) continue;
        let r = -1 !== e.indexOf(n);
        for (let e = 0; e < this.instrumentsArray[n].length; e++) {
          let i = 0,
            o = !0;
          if (this.instrumentsArray[n][e][1] === s.toUpperCase()) i = -100;
          else if (r)
            for (let s = 0; s < t.length; s++) {
              let r = this.instrumentsArray[n][e][1].indexOf(t[s]),
                a = -1;
              if (
                (this.instrumentsArray[n][e][4] &&
                  this.instrumentsArray[n][e].length > 4 &&
                  ((a = this.instrumentsArray[n][e][4].indexOf(t[s])),
                  a >= 0 && (a += 1)),
                -1 === r && -1 === a)
              ) {
                o = !1;
                break;
              }
              0 === r ? (i -= 2) : a && (i -= 1);
            }
          else o = !1;
          o && a.push([n, this.instrumentsArray[n][e], i, a.length]);
        }
      }
      return this.formatResults(a, r);
    }
    formatResults(e, t) {
      e.sort(function (e, t) {
        return e[2] === t[2] ? e[3] - t[3] : e[2] < t[2] ? -1 : 1;
      }),
        (e = e.slice(0, t));
      let s = [];
      for (let r = 0; r < e.length; r++) {
        let t = e[r][1],
          a = e[r][0];
        s.push(
          this.makeInstrument({
            exchangeToken: t[0],
            tradingSymbol: t[1],
            segment: a,
            exchange: this.segmentsExchangeMap[a],
            tickSize: t[2],
            lotSize: t[3],
            company: t[4] || '',
            isin: t[5] || '',
          })
        );
      }
      return s;
    }
    allSegements() {
      let e = [];
      return this.segments.forEach((t) => e.push(t)), e;
    }
    rankSegments(e) {
      let t = [],
        s = [];
      for (let i in e) e.hasOwnProperty(i) && (t.push(i), s.push(e[i]));
      let r = [],
        a = -1,
        n = -1;
      while (1) {
        if (((a = s.indexOf(Math.max.apply(Math, s))), -1 === a)) break;
        let e = s[a];
        if (((s[a] = 0), e < n)) break;
        (n = e), r.push(t[a]);
      }
      return r;
    }
    searchSegments(e) {
      let t = {},
        s = [];
      for (let r of this.segmentsList)
        for (let a = 0; a < e.length; a++)
          -1 !== this.segmentsAliases[r].indexOf(e[a]) &&
            (s.push(e[a]), t.hasOwnProperty(r) || (t[r] = 0), t[r]++);
      return {
        results: t,
        matchedTokens: this.unique(s),
      };
    }
    tokenize(e) {
      (e = this.trim(e).toUpperCase()),
        (e = this.trim(e.replace(/[^a-z0-9.\s&]/gi, ' '))),
        (e = e.replace(/[\s+]/gi, ' '));
      let t = e.split(' ');
      return this.unique(t);
    }
    trim(e) {
      return 'undefined' === typeof String.prototype.trim
        ? e.replace(/^\s+|\s+$/gm, '')
        : e.trim();
    }
    unique(e) {
      return e.sort().filter(function (t, s) {
        return !s || t !== e[s - 1];
      });
    }
    subtract(e, t) {
      let s = [];
      for (let r = 0; r < e.length; r++) -1 === t.indexOf(e[r]) && s.push(e[r]);
      return s;
    }
    arrayToSet(e) {
      let t = new Set();
      return e.forEach((e) => t.add(e)), t;
    }
    makeInstrument({
      exchangeToken: e,
      tradingSymbol: t,
      segment: s,
      exchange: r,
      tickSize: a,
      lotSize: n,
      company: i,
      isin: o,
      ignoreRelated: u,
    }) {
      let l = this.parse(t, r);
      if (
        ((l.segment = s),
        (l.exchange = r),
        (l.tickSize = a),
        (l.lotSize = n),
        (l.company = i),
        (l.tradable = this.tradeableSegments.has(s)),
        (l.precision = 'CDS' === r || 'BCD' === r ? 4 : 2),
        (l.fullName = this.getFullName(l)),
        (l.niceName = this.getNiceName(l)),
        (l.stockWidget = this.isStockWidget(l)),
        (l.exchangeToken = e),
        // (l.instrumentToken = (e << 8) + this.segmentsID[s]),
        (l.instrumentToken = e),
        (l.isin = o),
        (l.related = []),
        (l.underlying = this.getUnderlyingInstrument(l)),
        (l.auctionNumber = null),
        !u)
      ) {
        let e = this.equitySymbolMap[o || t];
        if (e)
          for (let t of e)
            if (t[0] !== s) {
              let e = this.instrumentsMap[t[0]][t[1]];
              if (!e) break;
              l.related.push(
                this.makeInstrument({
                  exchangeToken: e[0],
                  tradingSymbol: e[1],
                  segment: t[0],
                  exchange: this.segmentsExchangeMap[t[0]],
                  tickSize: e[2],
                  lotSize: e[3],
                  company: e[4] || '',
                  isin: e[5] || '',
                  ignoreRelated: !0,
                })
              );
            }
      }
      if (!i && l.related.length > 0)
        for (let c of l.related)
          if (c.company) {
            l.company = c.company;
            break;
          }
      // console.log(l)
      return (
        (l.isEvent =
          ('NSE' === r || 'BSE' === r || 'INDICES' === r) &&
          this.eventsInstruments[t]),
        (l.isWeekly = l.expiryWeek > 0),
        l
      );
    }
    getUnderlyingInstrument(e) {
      if (e && !this.IsEquity(e.exchange) && e.symbol) {
        let t = e.symbol,
          s = e.exchange;
        if ('NFO' === e.exchange) s = 'NSE';
        else {
          if ('BFO' !== e.exchange) return null;
          s = 'BSE';
        }
        'BANKNIFTY' === t
          ? ((t = 'NIFTY BANK'), (s = 'INDICES'))
          : 'NIFTY' === t
          ? ((t = 'NIFTY 50'), (s = 'INDICES'))
          : 'FINNIFTY' === t
          ? ((t = 'NIFTY FIN SERVICE'), (s = 'INDICES'))
          : 'MIDCPNIFTY' === t && ((t = 'NIFTY MID SELECT'), (s = 'INDICES'));
        let r = this.get(t, null, s);
        return r && r.exchangeToken ? r : null;
      }
      return null;
    }
    getFullName(e) {
      return 'BSE' === e.exchange
        ? e.symbol + ' (' + e.tradingSymbol + ')'
        : e.tradingSymbol;
    }
    getNiceName(e) {
      if ('EQ' === e.type) return e.tradingSymbol;
      let t = e.symbol;
      return (
        e.expiryDay && (t += ' ' + e.expiryDay + this.dateSuffix(e.expiryDay)),
        e.expiryMonth &&
          e.expiryYear &&
          (t +=
            ' ' +
            (e.expiryYear !== this.currentYear
              ? e.expiryYear.toString().substr(2, 4)
              : '') +
            this.months[e.expiryMonth - 1]),
        'OPT' === e.type
          ? (t += ' ' + e.strike + ' ' + e.optionType)
          : 'FUT' === e.type && (t += ' ' + e.type),
        t
      );
    }
    isStockWidget(e) {
      return this.equitySegments.has(e.exchange);
    }
    IsEquity(e) {
      return this.equitySegments.has(e) || 'INDICES' === e;
    }
    extractEqSymbol(e) {
      return e.length > 3 && '-' === e[e.length - 3] ? e.slice(0, -3) : e;
    }
    parse(e, t) {
      let s = '';
      if (-1 !== e.indexOf('|') && 'BSE' === t) {
        var r = e.split('|');
        (e = r[0]), (s = r[1]);
      }
      let a = {};
      (a.tradingSymbol = e), (a.scripCode = s);
      let n = this.regSymbol.exec(e);
      if (!n) return (a.type = 'EQ'), (a.symbol = this.extractEqSymbol(e)), a;
      if (
        ((a.symbol = n[1]),
        '-EQ' === n[2] && (a.type = 'EQ'),
        'FUT' === n[10]
          ? (a.type = 'FUT')
          : n[11] &&
            n[12] &&
            ((a.type = 'OPT'),
            (a.optionType = n[12]),
            (a.strike = parseFloat(n[11]))),
        n[4] && n[8] && n[9] && this.weeklyMonthsMap[n[8]])
      ) {
        let e = this.weeklyMonthsMap[n[8]];
        (a.expiryMonth = this.months.indexOf(e.toUpperCase()) + 1),
          (a.expiryYear = parseInt(n[4]) + 2e3),
          (a.expiryDay = parseInt(n[9])),
          (a.expiryWeek = Math.floor(parseInt(n[9]) / 7) + 1);
      } else
        n[4] &&
          n[5] &&
          ((a.expiryYear = parseInt(n[4]) + 2e3),
          (a.expiryMonth = this.months.indexOf(n[5].toUpperCase()) + 1));
      return a;
    }
    expandExpiryString(e) {
      const t = this.regWeeklyExpiry.exec(e);
      return t && t[5] && t[6]
        ? t[6] + ' ' + this.weeklyMonthsMap[t[5]] + ' WEEKLY'
        : e;
    }
    dateSuffix(e) {
      if (e > 3 && e < 21) return 'th';
      switch (e % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    }
    arrayToMap(e, t) {
      if (!e) return {};
      t || (t = '');
      let s = {};
      for (let r of e) s[t + r] = !0;
      return s;
    }
  }
  
  function r(e, t, s) {
    return (
      t in e
        ? Object.defineProperty(e, t, {
            value: s,
            enumerable: !0,
            configurable: !0,
            writable: !0,
          })
        : (e[t] = s),
      e
    );
  }
    
  export default Search
    