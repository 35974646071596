import { IChartingLibraryWidget } from "../charting_library/charting_library";
import { autoSaveKey, drawingToolbarID, Segment, THEME } from "../constants";
import store from "../datafeed/store";
import { queryParams } from "../helpers";
import { createAutoSaveButton, createPopOutButton } from "./elements";

export default (tvWidget: IChartingLibraryWidget) => {
  // on tvwidget header ready -------------------------------
  tvWidget.headerReady().then(() => {
    createAutoSaveButton(tvWidget);
    closeDrawingToolbarOnMobile(tvWidget);
    tvWidget.changeTheme(
      queryParams.get("theme") === "dark"
        ? THEME.DARK
        : queryParams.get("theme") === "light"
        ? THEME.LIGHT
        : localStorage.getItem("tradingview.current_theme.name") === "dark"
        ? THEME.DARK
        : THEME.LIGHT
    );
    createPopOutButton(tvWidget);
  });

  // On Chart ready -------------------------------
  tvWidget.onChartReady(() => {
    // set Auto Scale true
    const priceScale = tvWidget.activeChart().getPanes()[0].getMainSourcePriceScale();
    priceScale?.setAutoScale(true)

    // immediately inform about chart loaded
    informOnSymbolChanges(tvWidget.activeChart().symbol());
    // on Active chart change
    tvWidget
      .activeChart()
      .onSymbolChanged()
      .subscribe(null, () => {
        // immediately inform about chart changes on symbol change
        informOnSymbolChanges(tvWidget.activeChart().symbol());
      });
  });

  // on window message ----------------------------------------
  window.onmessage = (e) => {
    // probably symbol change request
    if (typeof e.data === "string") {
      const splittedData = e.data.split(":");
      if (splittedData.length === 2) {
        const [exchange, ticker] = splittedData;
        tvWidget.activeChart().setSymbol(`${exchange}:${ticker}`);
      }
    }
    // change theme
    if (e.data.theme) {
      tvWidget.changeTheme(e.data.theme === "dark" ? THEME.DARK : THEME.LIGHT);
    }

    // change symbol. !Not in use currently.
    if (e.data.symbol) {
      const splittedData = e.data.symbol.split(":");
      if (splittedData.length === 2) {
        const [exchange, ticker] = splittedData;
        tvWidget.activeChart().setSymbol(`${exchange}:${ticker}`);
      }
    }
  };

  // on plus icon click on price scale -------------------------------
  tvWidget.subscribe("onPlusClick", (a) => {
    tvWidget
      .activeChart()
      .createMultipointShape([{ time: 0, price: a.price }], {
        shape: "horizontal_line",
        lock: false,
        disableSave: false,
        zOrder: "top",
        overrides: {
          showPriceLabels: true,
          linewidth: 2,
          extendLeft: false,
          linestyle: 0,
        },
      });
  });

  // to save layout changes --------------------------------------------------
  tvWidget.subscribe("onAutoSaveNeeded", () => {
    const isAutoSaveOn = JSON.parse(
      localStorage.getItem(autoSaveKey) || "true"
    );
    if (isAutoSaveOn === true) {
      tvWidget.saveChartToServer(undefined, undefined, {
        defaultChartName: "Default Layout",
      });
    }
  });

  // collapse the drawing toolbar on screen resize to mobile && drawing toolbar is open
  window.onresize = () => {
    closeDrawingToolbarOnMobile(tvWidget);
  };
};

// collapses the drawing toolbar on small screen & if drawing toolbar is open
export const closeDrawingToolbarOnMobile = (
  tvWidget: IChartingLibraryWidget
) => {
  const isDrawingToolbarOpen = tvWidget
    .activeChart()
    .getCheckableActionState(drawingToolbarID);
  if (window.screen.width < 600 && isDrawingToolbarOpen) {
    tvWidget.activeChart().executeActionById(drawingToolbarID);
  }
};

const informOnSymbolChanges = (symbol: string) => {
  let [exchange, ticker, ..._other] = symbol.split(":");
  const token = store.getTokenFromTicker(symbol);

  if (exchange === Segment.INDICES) {
    exchange = Segment.NSE;
  }
  // change current window path
  window.history.replaceState("", "Title", `/${exchange}/${ticker}`);

  // change parent window path if present
  if (window.self !== window.top) {
    window.top?.postMessage(
      `${exchange}:${ticker}:${token}`,
      process.env.APP_URL || ""
    );
  }
};
