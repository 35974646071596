import dayjs from "dayjs";
import { IChartingLibraryWidget, widget } from "../charting_library";
import datafeed from "../datafeed";
import { Resolutions } from "../constants";
import { OiIndicator } from "./elements";
import { LocalStorageSaveLoadAdapter } from "./store";
import listenToEvents from "./events";

let tvWidget: IChartingLibraryWidget;

const getTvWidget = (): IChartingLibraryWidget => {
  if (tvWidget) {
    return tvWidget;
  }

  const locationSplits = decodeURIComponent(location.pathname).split("/");
  const exchange = locationSplits.at(1) || "NSE";
  const ticker = locationSplits.at(2) || "SBIN";
  const symbol = `${exchange}:${ticker}`;

  // Chart widget -----------------------------------------
  tvWidget = new widget({
    custom_css_url: `${window.location.protocol}//${window.location.host}/assets/mycss.css`,
    container: "tv_chart_container",
    symbol: symbol,
    locale: "en",
    auto_save_delay: 3,
    library_path: "/charting_library/",
    disabled_features:
      window.top === window.self ? [] : ["header_fullscreen_button"],
    datafeed,
    interval: Resolutions[1],
    load_last_chart: true,
    fullscreen: true,
    autosize: true,
    timezone: "Asia/Kolkata",
    save_load_adapter: new LocalStorageSaveLoadAdapter(),
    enabled_features: [
      "study_templates",
      "chart_crosshair_menu",
      "saveload_separate_drawings_storage",
    ],
    time_scale: {
      min_bar_spacing: 0.6,
    },
    timeframe: { from: dayjs().add(8, "M").unix(), to: dayjs().unix() },
    custom_indicators_getter(PineJS) {
      return Promise.resolve([OiIndicator(PineJS)]);
    },
  });

  // important since load_last_chart loads the layout but also takes us to the
  // last saved symbol.
  tvWidget.headerReady().then(() => {
    tvWidget.activeChart().setSymbol(symbol);
  });

  // starting to listen widgets for tvwidgets
  listenToEvents(tvWidget);

  return tvWidget;
};

export default getTvWidget;
