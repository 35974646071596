import { getCookieValue, handle401, queryParams, setCookie } from "./helpers";
import InitWidget from "./widget";

const session = queryParams.get("session") || getCookieValue("session");
const token =
  queryParams.get("token") ||
  queryParams.get("accessToken") ||
  getCookieValue("token") ||
  getCookieValue("accessToken");

// if no auth
if (!session || !token) {
  handle401();
}

setCookie("session", session!);
setCookie("token", token!);

(window as any).tvWidget = InitWidget();
