import { IBasicDataFeed } from "../charting_library/charting_library";
import "./store";
import {
  resolveSymbol,
  getBars,
  onReady,
  searchSymbols,
  subscribeBars,
  unsubscribeBars,
} from "./handlers";
import { connectSocket } from "./socket";

// socket connect ----------------------------------------
connectSocket();

export default <IBasicDataFeed>{
  onReady,
  searchSymbols,
  resolveSymbol,
  getBars,
  subscribeBars,
  unsubscribeBars,
};
