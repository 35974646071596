export const handle401 = () => {
  let currentHref = window.location.href;
  if (window.top && window.self !== window.top) {
    currentHref = window.top?.location.href;
  }
  window.location.replace(
    `${process.env.APP_URL}login?redirect=${currentHref}`
  );
};

export const getCookieValue = (name: string): string => {
  const regex = new RegExp(`(^| )${name}=([^;]+)`);
  const match = window.document.cookie.match(regex);
  if (match) {
    return match[2];
  }
  return "";
};

export function setCookie(name:string,value:string) {
  document.cookie = name + "=" + (value || "")   + "; path=/";
}

export const queryParams = new URLSearchParams(window.location.search);
