import {
  IChartingLibraryWidget,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
  StudyLinePlotPreferences,
  StudyMetaInfo,
  StudyPlotDisplayTarget,
  StudyPlotType,
} from "../charting_library/charting_library";
import { autoSaveKey, THEME } from "../constants";


// Creates a pop out chart button in top toolbar
export const createPopOutButton = (tvWidget: IChartingLibraryWidget) => {
  if (window.self === window.top) return;

  const button = tvWidget.createButton({
    align: "right",
    useTradingViewStyle: false,
  });

  const img = document.createElement("img");
  if (tvWidget.getTheme() === THEME.DARK) {
    img.src = "/assets/tv-popout-dark.svg";
    img.setAttribute("fill", "white");
  } else {
    img.src = "/assets/tv-popout.svg";
    img.setAttribute("fill", "white");
  }
  img.width = 18;
  button.appendChild(img);
  button.style.padding = "0px 10px";
  button.style.alignItems = "center";
  button.style.cursor = "pointer";

  button.classList.add("button-uO7HM85b");
  button.classList.add("iconButton-TPBYkbxL");
  button.classList.add("button-rqVQ8gUB");
  button.classList.add("apply-common-tooltip");
  button.classList.add("isInteractive-uO7HM85b");
  button.setAttribute("title", "Popout chart");

  button.addEventListener("click", () => {
    window.open(window.location.href, "_blank");
  });
};

// Custom indicator for open interest
export const OiIndicator = (
  PineJS: PineJS
): {
  name: string;
  metainfo: StudyMetaInfo;
  constructor(this: LibraryPineStudy<IPineStudyResult>): void;
} => ({
  name: "Open Interest",
  metainfo: {
    _metainfoVersion: 51,
    id: <RawStudyMetaInfoId>"Open Interest@tv-basicstudies-1",
    description: "Open Interest",
    shortDescription: "Open Interest",
    is_hidden_study: false,
    is_price_study: false,
    isCustomIndicator: true,
    format: {
      type: "price",
      precision: 2,
    },
    plots: [{ id: "plot_0", type: StudyPlotType.Line }],
    defaults: {
      styles: {
        plot_0: <StudyLinePlotPreferences>{
          linestyle: 0,
          visible: true,
          plottype: 0,
          linewidth: 2,
          trackPrice: false,
          color: "#009688",
          transparency: 50,
          display: StudyPlotDisplayTarget.All,
        },
      },
      precision: 2,
      inputs: {},
    },
    inputs: [],
  },
  constructor: function () {
    this.init = (context, inputCallback) => {
      this._context = context;
      this._input = inputCallback;
      const symbol = PineJS.Std.ticker(context);
      // Adding Extra OI to create a new symbol which is later processed in resolveSymbol
      this._context.new_sym(`${symbol}:OI`, PineJS.Std.period(context));
    };

    this.main = (context, inputCallback) => {
      this._context = context;
      this._input = inputCallback;

      this._context.select_sym(1);
      // Separate flow for close, we set a different symbol for handling it
      const v = PineJS.Std.close(context);
      if (Number.isNaN(v)) {
        return [0];
      }
      return [v];
    };
  },
});

// creates an autosave toggler in save menu
export const createAutoSaveButton = (tvWidget: IChartingLibraryWidget) => {
  const iframe = document.querySelector("iframe")!.contentWindow!;
  if (!iframe) return;

  // "v" icon in toolbar next to save
  const saveToggler = iframe.document.querySelector(
    'button[data-name="save-load-menu"]'
  );

  saveToggler?.addEventListener("click", () => {
    // putting as async since the menu is loaded async...hence dont want to load before it.
    // otherwise parentElement will be null.
    setTimeout(() => {
      const targetElement = iframe.document.querySelector(
        '[data-name="save-load-menu-item-save"]'
      );

      const parentElement = targetElement?.parentElement;
      if (!parentElement) return;

      // separator
      var separator = document.createElement("div");
      separator.classList.add("separator-QjUlCDId", "normal-QjUlCDId");
      separator.setAttribute("role", "separator");
      separator.setAttribute("aria-hidden", "false");
      parentElement.appendChild(separator);

      // label
      const label = document.createElement("label");
      label.textContent = "Auto save";
      label.style.padding = "2px 8px 2px 12px";
      label.style.display = "flex";
      label.style.alignItems = "center";
      label.style.justifyContent = "space-between";
      // if mobile screen
      if (window.screen.width <= 430){ // trading view switches to mobile layout at this resolution
        label.style.padding = "2px 8px 2px 20px";
        label.style.fontSize = "16px";
      }

      // toggle
      const toggle = document.createElement("input");
      toggle.classList.add("toggle");
      toggle.type = "checkbox";

      // setting checkbox based on history
      const isAutoSaveOn = JSON.parse(
        localStorage.getItem(autoSaveKey) || "true"
      );
      if (isAutoSaveOn === true) {
        toggle.checked = true;
      } else {
        toggle.checked = false;
      }

      // on toggle. set to localStorage
      toggle.addEventListener("click", () => {
        localStorage.setItem(autoSaveKey, JSON.stringify(toggle.checked));
      });

      label.appendChild(toggle);
      parentElement.appendChild(label);
    }, 0);
  });
};
