import { ResolutionString } from "./charting_library/charting_library";

export const Resolutions = {
  "1": <ResolutionString>"1",
  "2": <ResolutionString>"2",
  "3": <ResolutionString>"3",
  "4": <ResolutionString>"4",
  "5": <ResolutionString>"5",
  "10": <ResolutionString>"10",
  "15": <ResolutionString>"15",
  "30": <ResolutionString>"30",
  "60": <ResolutionString>"60",
  "120": <ResolutionString>"120",
  "180": <ResolutionString>"180",
  "1D": <ResolutionString>"1D",
  "1W": <ResolutionString>"1W",
  "1M": <ResolutionString>"1M",
};

export enum Segment {
  "INDICES" = "INDICES",
  "NSE" = "NSE",
  "BSE" = "BSE",
  "NFO-FUT" = "NFO-FUT",
  "NFO-OPT" = "NFO-OPT",
  "CDS-FUT" = "CDS-FUT",
  "BCD-FUT" = "BCD-FUT",
  "CDS-OPT" = "CDS-OPT",
  "MCX-FUT" = "MCX-FUT",
  "MCX-OPT" = "MCX-OPT",
}

export enum Environment {
  "PRODUCTION" = "production",
  "DEVELOPMENT" = "development",
  "STAGING" = "staging",
}

export enum MODE {
  LTPC = "ltpc",
  QUOTE = "quote",
  FULL = "full",
}

export const allSegments = [
  Segment["INDICES"],
  Segment["NSE"],
  Segment["BSE"],
  Segment["NFO-FUT"],
  Segment["NFO-OPT"],
  Segment["CDS-FUT"],
  Segment["BCD-FUT"],
  Segment["CDS-OPT"],
  Segment["MCX-FUT"],
  Segment["MCX-OPT"],
];

// exchange - unix time difference. Exchange sends seconds counting from 1980
export const tenYearsDuration = 315513000;

export enum CODE {
  SUB = "sub",
  UNSUB = "unsub",
}

export enum THEME {
  LIGHT = "light",
  DARK = "dark",
}


export const drawingToolbarID = "drawingToolbarAction"
export const autoSaveKey = "isAutoSaveOn"